<template>
  <address-lookup
    :readonly="readonly"
    :address="address"
    :country="countryCode"
    :country-name="countryName"
    :use-country="useCountry"
    @update:field="$emit('update', $event)"
    @noGoogle="$emit('noGoogle')"
  />
</template>
<script>

import AddressLookup from '@/shared/address_lookup.vue'

export default {
  components: { AddressLookup },
  props: {
    address: {
      type: Object
    },
    countryCode: {
      type: String
    },
    countryName: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    useCountry: {
      type: Boolean,
      default: false
    }
  }
}
</script>
